import React, {useEffect, useState} from 'react'
import {Card, Grid, Image,Header,Container} from 'semantic-ui-react'
import {API} from '../API'

function Category() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
            API.getSeries()
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div></div>;
    } else {

        return (
            <Container>
                <Header size={"large"}>视频列表</Header>
                <Grid columns={4}>
                    {items.map(item => (
                        <Grid.Column key={item.id}>
                            <Card as="a" href={"/category/"+item.serie_url}>
                                <Image
                                    fluid
                                    label={{
                                        color: item.if_end === 1 ? 'blue' : 'red',
                                        content: item.subtitle,
                                        ribbon: "right",
                                    }}
                                    src={item.image}
                                />
                                <Card.Content>
                                    <Card.Header textAlign={"center"}>{item.title}</Card.Header>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    ))}
                </Grid>
            </Container>
        );
    }


}


export default Category
