import React from 'react'
import {Container, Grid, Image, Page, Menu, Item, Input, Comment, Divider, List, Icon} from 'semantic-ui-react'

function Footer(){

    return (
        <Container>
            <Divider />
            <List size={"large"} verticalAlign={"middle"} horizontal={true} >
                <List.Item disabled={true}>Don't forget to be Awesome!</List.Item>
                <List.Item disabled={true}>©Crash Course字幕组</List.Item>

            </List>
            <List size={"large"} verticalAlign={"middle"} horizontal={true} floated={"right"}>
                <List.Item href={"https://weibo.com/u/5237129097/" } target="_blank" position="right"><List.Icon name="weibo" size={"large"} /></List.Item>
                <List.Item href={"http://qm.qq.com/cgi-bin/qm/qr?k=tsV0IB3mxatvvHzuPQmet4yZTL6vn3Sx"} target="_blank" position="right"><List.Icon name="qq" size={"large"} /></List.Item>
                <List.Item href={"/rss" } target="_blank" position="right"><List.Icon name="rss" size={"large"}  /></List.Item>
            </List>
        </Container>

    );
}

export default Footer;
