import React from 'react'
import {Container, Grid, Image, Page, Menu, Item, Input, Comment, Form} from 'semantic-ui-react'
import {Link} from "react-router-dom";

function Header(){

    return (
        <Container>
        <Menu text size="massive">
            <Menu.Item><a href="/"> <Image src={"https://cdn.jsdelivr.net/gh/mrinflated/ccwebAssets@main/static/favicon.png"}/></a></Menu.Item>
            <Menu.Item as={"h2"}>
                <a href="/">Crash Course 中文字幕组</a>
            </Menu.Item>
            <Menu.Menu position="right">
                <Menu.Item as={"a"} href="/" name="最近更新" />
                <Menu.Item as={"a"} href="/category" name="视频列表" />
                <Menu.Item as={"a"} href="/support" name="支持与鸣谢" />
                <Menu.Item>
                    <Form method="GET" action={"/"}>
                        <Form.Input size={"medium"} transparent={true} icon="search" placeholder="Search..." name={"q"} type={"text"}/>
                    </Form>

                </Menu.Item>
            </Menu.Menu>
        </Menu>
        </Container>
    );
}

export default Header;
