import React, {useEffect, useState} from 'react';
import {Card, Grid, Image, Header, Container, List, Icon} from 'semantic-ui-react';
import {API} from '../API'
import {useParams} from 'react-router-dom'
function Video() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    let {serie_url} = useParams();

    useEffect(() => {
        API.getSeries()
            .then(
                (result)=> {
                    return  result.find(element => element.serie_url==serie_url);
                },
                (error) => {
            setIsLoaded(true);
            setError(error);
        }
        )
            .then((serie) =>
        API.getVideos(serie.id)
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
            )

    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div></div>;
    } else {

        return (
            <Container>
                <Header size={"large"}>{items[0]?items[0].serie_name:null} <a href={"/category/"+serie_url+"/rss"}><Icon name="rss" size={"small"} /></a></Header>

                <Grid columns={4}>
                    {items.map(item => (
                        <Grid.Column key={item.id}>
                            <Card as="a" href={item.video_link}>
                                <Image
                                    fluid
                                    src={item.image}
                                />
                                <Card.Content>
                                    <Card.Header as={"h4"} className="video_title" textAlign={"center"}>第 {item.number} 集：{item.title}</Card.Header>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    ))}
                </Grid>
            </Container>
        );
    }


}


export default Video
