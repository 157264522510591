import React, {useEffect, useState} from 'react';
import {Card, Grid, Image,Header,Container} from 'semantic-ui-react';
import {API} from '../API'
import {useParams} from 'react-router-dom'

function SerieRss() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState("");
    const {serie_url} = useParams();

    useEffect(() => {
        API.getSeries()
            .then(
                (result)=> {
                    console.log(result.find(element => element.serie_url==serie_url));
                    return result.find(element => element.serie_url==serie_url);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
            .then((serie)=>
        API.getSerieRss(serie.id)
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            ))
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div></div>;
    } else {
        return items;
    }
}

function Rss() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState("");

    useEffect(() => {
        API.getRss()
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div></div>;
    } else {
        return items;
    }
}


export default {Rss,SerieRss};
