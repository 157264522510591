import {API_URL} from "./config.json";

 export class API{

     static getSeries(){
         return fetch(API_URL+'/serie', {
             method:'GET',
             headers:{
                 'Content-type':'application/json'
             }
         })
             .then(res => res.json())
     }

     static getNewest(){
         return fetch(API_URL+"/newest",{
             method:'GET',
             headers: {
                 'Content-type': 'application/json'
             }
         })
             .then(res => res.json())
     }

     static getSearch(query){
         return fetch(API_URL+'/episodes?q='+query,{
             method:'GET',
             headers: {
                 'Content-type': 'application/json'
             }
         })
             .then(res => res.json())
     }

     static getVideos(serie_id){
         return fetch(API_URL+'/serie/'+serie_id,{
             method:'GET',
             headers: {
                 'Content-type': 'application/json'
             }
         })
             .then(res => res.json())
     }

     static getRss(){
         return fetch(API_URL+'/rss',{
             method:'GET',
             headers: {
                 'Content-type': 'application/xml'
             }
         })
             .then(res => res.text())
     }

     static getSerieRss(serie_id){
         return fetch(API_URL+`/serie/${serie_id}/rss`,{
             method:'GET',
             headers: {
                 'Content-type': 'application/xml'
             }
         })
             .then(res => res.text())

     }
 }




