import React from 'react'
import {Card, Container, Header, Image, Segment} from "semantic-ui-react";


function Support(){
    return(
        <Container>
            <Segment>
                网站服务维护：<a href="https://weibo.com/2207493917/" target="_blank">@不想膨胀的小R同学 </a>
            </Segment>

            <Segment>
                    Crash Course 官方网址：
                    <a href="https://thecrashcourse.com/" target="_blank"> https://thecrashcourse.com </a>
                    <br /> Crash Course Petron捐助页面：
                        <a href="http://www.patreon.com/crashcourse" target="_blank"> http://www.patreon.com/crashcourse </a>
                    <br /> Crash Course 官方周边：
                            <a href="https://store.dftba.com/collections/crashcourse" target="_blank">
                                https://store.dftba.com/collections/crashcourse </a>
            </Segment>
            <Segment>
                Crash Course 字幕组1群：305631757
                <br/>
                Crash Course 字幕组2群：713907644
            </Segment>
    </Container>
    )
}

export default Support;

