import React, {useEffect, useState} from 'react'
import {API} from '../API'
import {Card, Grid, Image, Header, Container, Item, Icon} from 'semantic-ui-react'
import {useLocation, useParams} from "react-router-dom";
import Category from "./Category";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Newest() {
    let query = useQuery();
    const q = query.get("q");
    if(q)
    {
        return <Search query={q}/>;
    }
    else return <NewestPage/>;
}

function Search(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const query = props.query;

    useEffect(() => {
        API.getSearch(query)
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div></div>;
    } else {
        return (
            <Container>
                <Header size={"large"}>包含'{query}'的内容</Header>
                <Grid columns={4}>
                    {items.map(item => (
                        <Grid.Column key={item.id}>
                            <Card as="a" href={item.video_link}>
                                <Image
                                    fluid
                                    src={item.image}
                                />
                                <Card.Content>
                                    <Card.Header as={"h4"} className="video_title" textAlign={"center"}>{item.title}</Card.Header>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    ))}
                </Grid>
            </Container>
        );
    }


    return null;
}


function NewestPage() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [items, setItems] = useState([]);

    const tranferDate = (datetime) =>{
        let date = new Date(datetime);
        //return date.getFullYear()+"年"+date.getUTCMonth()+"月"+date.getUTCDay()+"日";
        return date.toLocaleDateString();
    }

    useEffect(() => {
        API.getNewest()
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.results);
                    console.log(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div></div>;
    } else {
        //const {count,next,previous,results} = items;
        console.log(items);


        return (
            <Container>
                <Header size="huge">最近更新</Header>
                <Item.Group>
                    {items.map(item => (
                        <Item key={item.id} >
                            <Item.Image as='a' href={item.video_link} size='medium' src={item.image_cdn?item.image_cdn:item.image}/>
                            <Item.Content verticalAlign='middle'>
                                <Item.Meta className='date'>{tranferDate(item.updated_at)}</Item.Meta>
                                <Item.Meta>
                                    <Header as="h4" >
                                        <a href={"/category/"+item.serie_url}>{item.serie_name}</a>
                                        第 {item.number} 集
                                    </Header>
                                </Item.Meta>
                                <Header size="large" as='a' href={item.video_link}>{item.title}</Header>
                            </Item.Content>
                        </Item>

                    ))}

                </Item.Group>
            </Container>

        );
    }


}


export default Newest
