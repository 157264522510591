import React from "react";
import ReactDOM from "react-dom";
import './App.css';
import Newest from "./components/Newest";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    Link
} from "react-router-dom";
import {API} from './API'
import Category from "./components/Category";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Video from "./components/Video";
import Rss from "./components/Rss";
import Support from "./components/Support";


// TODO: Switch to https://github.com/palmerhq/the-platform#stylesheet when it will be stable
/*
const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);
*/
function App(props) {
    return (
        <React.Fragment>
            <Header/>
            {props.children}
            <Footer/>
        </React.Fragment>
    )
}

ReactDOM.render((
        <Router>
            <Switch>

                <Route exact path="/">
                    <App children={<Newest/>}/>
                </Route>

                <Route path="/category/:serie_url/rss">
                    <Rss.SerieRss/>
                </Route>
                <Route path="/category/:serie_url">
                    <App children={<Video/>}/>
                </Route>


                <Route path="/category">
                    <App children={<Category/>}/>
                </Route>

                <Route path="/support">
                    <App children={<Support/>}/>
                </Route>

                <Route path="/rss">
                    <Rss.Rss />
                </Route>
            </Switch>


        </Router>

), document.getElementById('root'))

